var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CRow",
    [
      _c(
        "CCol",
        { attrs: { col: "12", lg: "12" } },
        [
          _c(
            "CCard",
            { attrs: { "accent-color": _vm.accentColor } },
            [
              _c(
                "CCardHeader",
                { attrs: { color: _vm.backColor, "text-color": "light" } },
                [
                  _c("span", { staticClass: "float-left" }, [
                    _c("h2", [_vm._v(_vm._s(_vm.username))])
                  ])
                ]
              ),
              _c(
                "CCardBody",
                [
                  _c("UserDetailsCard", { attrs: { user: _vm.user } }),
                  _c(
                    "CForm",
                    { attrs: { novalidate: "" } },
                    [
                      _c(
                        "CCard",
                        {
                          staticClass: "px-2",
                          attrs: { id: "user-settings-card" }
                        },
                        [
                          _c(
                            "CCardHeader",
                            [
                              _c(
                                "CRow",
                                { staticClass: "justify-content-around" },
                                [
                                  _c("CCol", { attrs: { col: "10" } }, [
                                    _c("span", { staticClass: "float-left" }, [
                                      _c("h5", [_vm._v("Profile")])
                                    ])
                                  ]),
                                  _c("CCol", { attrs: { col: "2" } }, [
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: !_vm.edit,
                                            expression: "!edit"
                                          }
                                        ],
                                        staticClass: "float-right"
                                      },
                                      [
                                        _c(
                                          "CButton",
                                          {
                                            attrs: {
                                              color: "info",
                                              variant: "outline"
                                            },
                                            on: { click: _vm.editClick }
                                          },
                                          [
                                            _c("CIcon", {
                                              attrs: { name: "cil-pencil" }
                                            }),
                                            _vm._v(" Edit ")
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "CCardBody",
                            [
                              _c(
                                "CRow",
                                [
                                  _c(
                                    "CCol",
                                    [
                                      _c("CInput", {
                                        attrs: {
                                          type: "text",
                                          label: "First Name",
                                          readonly: !_vm.edit,
                                          disabled: !_vm.canEdit,
                                          value: _vm.form.first
                                        },
                                        on: {
                                          "update:value": function($event) {
                                            return _vm.$set(
                                              _vm.form,
                                              "first",
                                              $event
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "CRow",
                                [
                                  _c(
                                    "CCol",
                                    [
                                      _c("CInput", {
                                        attrs: {
                                          type: "text",
                                          label: "Last Name",
                                          readonly: !_vm.edit,
                                          disabled: !_vm.canEdit,
                                          value: _vm.form.last
                                        },
                                        on: {
                                          "update:value": function($event) {
                                            return _vm.$set(
                                              _vm.form,
                                              "last",
                                              $event
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              false
                                ? _c(
                                    "CRow",
                                    [
                                      _c("CCol", [
                                        _c("table", [
                                          _c("thead"),
                                          _c("tbody", [
                                            _c("tr", [
                                              _c(
                                                "td",
                                                [
                                                  true
                                                    ? _c(
                                                        "CSwitch",
                                                        _vm._b(
                                                          {
                                                            staticClass:
                                                              "disabled",
                                                            attrs: {
                                                              label: "",
                                                              color: "info",
                                                              size: "lg",
                                                              shape: "pill",
                                                              readonly: !_vm.edit,
                                                              disabled: !_vm.canEdit,
                                                              value:
                                                                _vm.user
                                                                  .darkMode,
                                                              variant: "3d"
                                                            }
                                                          },
                                                          "CSwitch",
                                                          _vm.labelIcon,
                                                          false
                                                        )
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              ),
                                              _c("td", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      float: "left",
                                                      "margin-left": "10px",
                                                      "margin-top": "-5px"
                                                    }
                                                  },
                                                  [_vm._v("Toggle Dark Mode")]
                                                )
                                              ])
                                            ])
                                          ])
                                        ])
                                      ])
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "CRow",
                                [
                                  _c(
                                    "CCol",
                                    [
                                      _c("CInput", {
                                        staticClass: "pb-0",
                                        attrs: {
                                          type: "email",
                                          label: "Email",
                                          vertical: "",
                                          readonly: !_vm.enableChangeEmail,
                                          value: _vm.user.email,
                                          required: "",
                                          "was-validated": ""
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "prepend-content",
                                            fn: function() {
                                              return [
                                                _c("CIcon", {
                                                  attrs: {
                                                    name: "cil-envelope-closed"
                                                  }
                                                })
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ])
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "CRow",
                                { staticClass: "m-0 p-0" },
                                [
                                  _c(
                                    "CCol",
                                    {
                                      staticClass: "my-0 py-0 text-right",
                                      attrs: { col: "12" }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            color: "var(--success)"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.passwordMessage) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _vm.edit
                                        ? _c(
                                            "CButton",
                                            {
                                              staticClass: "m-0 p-1",
                                              attrs: {
                                                color: "danger",
                                                size: "custom"
                                              },
                                              on: {
                                                click: _vm.resetPasswordClick
                                              }
                                            },
                                            [
                                              _c("CIcon", {
                                                attrs: {
                                                  name: "cil-lock-locked"
                                                }
                                              }),
                                              _vm._v(" Change Password ")
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c("CFormGroup", {
                                attrs: {
                                  wrapperClasses: "input-group pt-2",
                                  description: "ex. (999) 999-9999"
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "prepend-content",
                                    fn: function() {
                                      return [
                                        _c("CIcon", {
                                          attrs: { name: "cil-phone" }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  },
                                  {
                                    key: "label",
                                    fn: function() {
                                      return [_vm._v(" Phone Number ")]
                                    },
                                    proxy: true
                                  },
                                  {
                                    key: "input",
                                    fn: function() {
                                      return [
                                        _c("masked-input", {
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "tel",
                                            name: "phone",
                                            mask: [
                                              "(",
                                              /[1-9]/,
                                              /\d/,
                                              /\d/,
                                              ")",
                                              " ",
                                              /\d/,
                                              /\d/,
                                              /\d/,
                                              "-",
                                              /\d/,
                                              /\d/,
                                              /\d/,
                                              /\d/
                                            ],
                                            guide: true,
                                            readonly: !_vm.edit,
                                            disabled: !_vm.canEdit,
                                            placeholderChar: "#"
                                          },
                                          model: {
                                            value: _vm.contact,
                                            callback: function($$v) {
                                              _vm.contact = $$v
                                            },
                                            expression: "contact"
                                          }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ])
                              })
                            ],
                            1
                          ),
                          _c(
                            "CCardFooter",
                            [
                              _c(
                                "CRow",
                                { staticClass: "justify-content-center" },
                                [
                                  _c("CCol"),
                                  _c(
                                    "CCol",
                                    { attrs: { lg: "6" } },
                                    [
                                      _c(
                                        "CButtonGroup",
                                        { staticClass: "float-right" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: _vm.edit,
                                                  expression: "edit"
                                                }
                                              ],
                                              staticClass: "float-right"
                                            },
                                            [
                                              _c(
                                                "CButton",
                                                {
                                                  attrs: {
                                                    color: "secondary",
                                                    variant: "outline"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.edit = !_vm.edit
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("CIcon", {
                                                    attrs: {
                                                      name: "cil-x-circle"
                                                    }
                                                  }),
                                                  _vm._v(" Cancel ")
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: _vm.edit,
                                                  expression: "edit"
                                                }
                                              ]
                                            },
                                            [
                                              _c(
                                                "CButton",
                                                {
                                                  attrs: {
                                                    color: "info",
                                                    variant: "outline"
                                                  },
                                                  on: {
                                                    click: _vm.handleSaveClick
                                                  }
                                                },
                                                [
                                                  _c("CIcon", {
                                                    attrs: { name: "cil-save" }
                                                  }),
                                                  _vm._v(" Save ")
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("CRow", [_c("CCol", { attrs: { lg: "12" } })], 1)
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }