/* eslint-disable vue/no-reserved-keys */


/** Use this mixin on Components that you want to allow 
 * Applying a Theme from $store or from the $app Settings
 * 
 * Applies Global Themes to Card Components
 */
import Vue from 'vue'; 


const ThemeMixin = Vue.extend( {
    name: "ThemeMixin", 
    props:{
        /** Nothing Yet */
    }, 
    data(){
        return {
            _applied: false, 
            _theme: undefined, 
        }
    }, 
    created(){
        try{
            this._theme = this.$store.getters.theme; 
        }
        catch(err){
            console.log(err); 
        }
    }, 
    computed:{
        
      theme(){
          if(this.$store.getters.theme){
              return this.$store.getters.theme;
          }
          else{
              return this._theme; 
          }
      }, 
      backColor(){
        if(this.theme){
            return this.theme.backColor;
        }
        return "dark"; // Default Backcolor
      }, 
      accentColor(){
        if(this.theme){
            return this.theme.accentColor;
        }
        return ""; // Default Accent Color
      },
      textColor(){
        if(this.theme){
            return this.theme.textColor;
        }
      }, 
      iconColor(){
        
      },
      backgroundImage(){

      }
      
    }, 
    methods:{
      
       applyTheme(){

       }
    }
}); 


export default ThemeMixin; 

