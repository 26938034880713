<template>
  <CRow>
    <CCol col="12" lg="12">
      <CCard :accent-color="accentColor">
        <CCardHeader :color="backColor" text-color="light">
          <span class="float-left"
            ><h2>{{ username }}</h2></span
          >
        </CCardHeader>
        <CCardBody>
          <UserDetailsCard :user="user" />

          <CForm novalidate>
            <CCard id="user-settings-card" class="px-2">
              <CCardHeader>
                <CRow class="justify-content-around">
                  <CCol col="10">
                    <span class="float-left"><h5>Profile</h5></span>
                  </CCol>
                  <CCol col="2">
                    <span v-show="!edit" class="float-right">
                      <CButton
                        color="info"
                        variant="outline"
                        @click="editClick"
                      >
                        <CIcon name="cil-pencil" />
                        Edit
                      </CButton></span
                    >
                  </CCol>
                </CRow>
              </CCardHeader>
              <CCardBody>
                <CRow>
                  <CCol>
                    <CInput
                      type="text"
                      label="First Name"
                      :readonly="!edit"
                      :disabled="!canEdit"
                      :value.sync="form.first"
                    >
                    </CInput>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol>
                    <CInput
                      type="text"
                      label="Last Name"
                      :readonly="!edit"
                      :disabled="!canEdit"
                      :value.sync="form.last"
                    >
                    </CInput>
                  </CCol>
                </CRow>
                <CRow v-if="false">
                  <CCol>
                    <table>
                      <thead></thead>
                      <tbody>
                        <tr>
                          <td>
                            <CSwitch
                              v-if="true"
                              class="disabled"
                              label
                              v-bind="labelIcon"
                              color="info"
                              size="lg"
                              shape="pill"
                              :readonly="!edit"
                              :disabled="!canEdit"
                              :value="user.darkMode"
                              variant="3d"
                            />
                          </td>
                          <td>
                            <span
                              style="float:left; margin-left:10px; margin-top:-5px;"
                              >Toggle Dark Mode</span
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <!--CSelect
                      label="User Role"
                      :readonly="!edit"
                      :diabled="!edit"
                      :value="user.role"
                      :options="options"
                      placeholder=""
                    >
                      <template #prepend-content
                        ><CIcon name="cil-user"
                      /></template>
                    </CSelect-->
                  </CCol>
                </CRow>
                <CRow>
                  <CCol>
                    <CInput
                      class="pb-0"
                      type="email"
                      label="Email"
                      vertical
                      :readonly="!enableChangeEmail"
                      :value="user.email"
                      required
                      was-validated
                    >
                      <template #prepend-content
                        ><CIcon name="cil-envelope-closed"
                      /></template>
                    </CInput>
                  </CCol>
                </CRow>
                <CRow  class="m-0 p-0">
                  <CCol col="12" class="my-0 py-0 text-right">
                    <span style="color:var(--success)"> {{passwordMessage}} </span>
                    <CButton v-if="edit"
                      color="danger"
                      size="custom"
                      class="m-0 p-1"
                      @click="resetPasswordClick"
                    >
                      <CIcon name="cil-lock-locked" />
                      Change Password
                    </CButton>
                  </CCol>
                </CRow>

                <CFormGroup
                  wrapperClasses="input-group pt-2"
                  description="ex. (999) 999-9999"
                >
                  <template #prepend-content>
                    <CIcon name="cil-phone" />
                  </template>
                  <template #label>
                    Phone Number
                  </template>
                  <template #input>
                    <masked-input
                      type="tel"
                      name="phone"
                      class="form-control"
                      v-model="contact"
                      :mask="[
                        '(',
                        /[1-9]/,
                        /\d/,
                        /\d/,
                        ')',
                        ' ',
                        /\d/,
                        /\d/,
                        /\d/,
                        '-',
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/
                      ]"
                      :guide="true"
                      :readonly="!edit"
                      :disabled="!canEdit"
                      placeholderChar="#"
                    />
                  </template>
                </CFormGroup>
              </CCardBody>
              <CCardFooter>
                <CRow class="justify-content-center">
                  <CCol> </CCol>

                  <CCol lg="6">
                    <CButtonGroup class="float-right">
                      <span v-show="edit" class="float-right">
                        <CButton
                          color="secondary"
                          variant="outline"
                          @click="edit = !edit"
                        >
                          <CIcon name="cil-x-circle" />
                          Cancel
                        </CButton></span
                      >

                      <span v-show="edit">
                        <CButton
                          color="info"
                          variant="outline"
                          @click="handleSaveClick"
                        >
                          <CIcon name="cil-save" />
                          Save
                        </CButton></span
                      >
                    </CButtonGroup>
                  </CCol>
                </CRow>
              </CCardFooter>
            </CCard>
          </CForm>

          <!--CCard>
            <CCardBody>
              <CDataTable
                striped
                small
                fixed
                :items="visibleData"
                :fields="fields"
              />
            </CCardBody>
          </CCard-->
          <CRow>
            <CCol lg="12"> </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>



<style scoped>
.label {
  overflow-wrap: normal;
}
</style>
            

            


<script>
import MaskedInput from "vue-text-mask";
import UserDetailsCard from "../components/users/UserDetailsCard";

import ThemeMixin from "../mixins/ThemeMixin"; 

const defaultIMG =
  "https://poc.aarcomm.io/app/grafana/avatar/fd14f83a37594373470f1c9fdb18ad08";

export default {
  name: "Profile",
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.usersOpened = from.fullPath.includes("users");
    });
  },
  data() {
    return {
      form: this.getForm(),
      usersOpened: null,
      canEdit: false,
      edit: false, 
      passwordMessage: "", 
    };
  },
  mixins:[ThemeMixin], 
  components: {
    MaskedInput,
    UserDetailsCard
  },
  computed: {
    enableChangeEmail() {
      if (!this.edit) return false;
      else if (this.$auth.isAdmin() === false) return false;
      if (this.$auth.isAdmin() && !this.edit) {
        return true;
      } else return false;
    },
    
    options() {
      return [
        {
          key: "0",
          label: "Viewer Only",
          value: "viewer",
          disabled: !this.edit
        },
        { key: "1", label: "Operator", value: "user", disabled: !this.edit },
        { key: "2", label: "Admin", value: "admin", disabled: !this.edit }
      ];
    },
    userInfo() {
      // Returns a map of user summary info
      return [
        { key: "uid", label: "User ID:", value: this.userId },
        { key: "role", label: "User Role:", value: this.role },

        {
          key: "registered",
          label: "Registered: ",
          value: this.getDateTime(this.user.registered)
        }
      ];
    },
    status() {
      return "Active";
    },
    contact: {
      get() {
        return this.user.phone_number;
      },
      set(val) {
        // TODO:
      }
    },
    userId: function() {
      return this.$auth.user_id;
    },
    user() {
      if (!this.$auth.userData) {
        return {
          first: "",
          last: "",
          status: this.status,
          img: this.userImage,
          role: this.role,
          uid: this.userId
        };
      } else {
        return {
          ...this.$auth.userData,
          status: this.status,
          img: this.userImage,
          role: this.role,
          uid: this.userId
        };
      }
    },
    defaultImg() {
      return defaultIMG;
    },
    userImage() {
      if (this.$auth.user.picture) return this.$auth.user.picture;
      else return defaultIMG;
    },
    userEmail() {
      return this.user.email;
      //return this.userData.filter(param => param.key === "email")[0].value;
    },
    fields() {
      return [
        { key: "key", label: this.username, _style: "width:150px" },
        { key: "value", label: "", _style: "width:150px;" }
      ];
    },
    role() {
      let item = this.$auth;

       return this.$app.getUserRole(item.role);
    },
    userData() {
      const id = this.userId;
      const user = this.user;
      const userDetails = user ? Object.entries(user) : [["id", "Not found"]];
      return userDetails.map(([key, value]) => {
        return { key, value };
      });
    },
    visibleData() {
      return this.userData.filter(param => param.key !== "username");
    },
    username() {
      let temp = ""; 
      if(!this.user)
        return "";
      return `${this.user.first?this.user.first:""} ${this.user.last?this.user.last:""}`;
    },
    first() {
      return this.user.first;
    },
    last() {
      return this.user.last;
    },
    raw() {
      return JSON.stringify(this.$auth.user, null, 2);
    }
  },
  methods: {
    editClick(e){
       this.edit = !this.edit;
       this.passwordMessage = ""; 
    }, 
    handleSaveClick(e) {
      this.edit = !this.edit;
      // TODO: Save user Settings here
    },
    notifyPasswordReset(){
      this.passwordMessage = "We Sent you an Email with instructions how to change your password"
      // TODO: User must sign out and re-sign in 
    }, 
    async resetPasswordClick(e) {
      this.edit = false;
      // TODO: Initiate Reset Password
      try {
        let body = {
          client_id: undefined,
          email: this.userEmail,
          connection: this.user.connection
        };
        console.log(body); 
        let res = await this.$api.resetPassword(body);
        if (res) {
          console.log(res);
          if(res.status==200){
            this.notifyPasswordReset(); 
          }
        }
      } catch (err) {
        console.log(err);
      } finally {
      }
    },
    goBack() {
      this.usersOpened
        ? this.$router.go(-1)
        : this.$router.push({ path: "/users" });
    },
    getDateTime(date) {
      return this.$moment(date).format("MMMM Do YYYY, h:mm:ss a");
    },
    getEmail(item) {
      if (!item || !item.email) return "";

      return `<a href="mailto:${item.email}" target="_blank">${item.email} </a>`;
    },
    getBadgeColor(status) {
      if (!this.status) {
        return "danger";
      }
      switch (this.status.toLowerCase()) {
        case "active":
          return "success";
          break;
        case "inactive":
          return "warning";
          break;
        default:
          return "danger";
      }
    },

    load() {
      const id = this.userId;
      this.user = usersData.find((user, index) => index + 1 == id);
      const userDetails = this.user
        ? Object.entries(this.user)
        : [["id", "Not found"]];
      return userDetails.map(([key, value]) => {
        return { key, value };
      });
    },
    getForm() {
      return {
        first: this.first,
        last: this.last,
        email: this.email,
        contact: this.contact
      };
    }
  },
  activated() {
    //this.load();
    this.form = this.getForm();
    console.log(this.user);
    this.passwordMessage =""; 
  },
  mounted() {
    //this.load();
    this.form = this.getForm();
    this.passwordMessage =""; 

  }
};
</script>


